@font-face {
    font-family: "Campuni-Light";
    src: url('./assets/fonts/Campuni-Light.otf');
}

@font-face {
    font-family: "Campuni-Medium";
    src: url('./assets/fonts/Campuni-Medium.otf');
}

@font-face {
    font-family: "Campuni-Bold";
    src: url('./assets/fonts/Campuni-Bold.otf');
}


@font-face {
    font-family: "AlegraSans";
    src: url('./assets/fonts/AlegreyaSans-Light.ttf');
}


@font-face {
    font-family: "AlegraSans-Medium";
    src: url('./assets/fonts/AlegreyaSans-Medium.ttf');
}

@font-face {
    font-family: "Univers-Medium";
    src: url('./assets/fonts/Univers\ CE\ 55\ Medium.ttf');
}

body {
    margin: 0;
    padding: 0;
    letter-spacing: 0.8px !important;
    font-family: 'Campuni-Medium' !important;
    background-color: '#f9f9f9' !important;
    /* max-width: 1380px !important; */
}

button {
    font-family: 'Campuni-Medium' !important;
    text-transform: capitalize !important;

}

.dots-paragrafo {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.dots-2linhas {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

#select-multiple-native {
    height: 350px !important;
}

#select-multiple-native option:checked {
    background: transparent !important;
    font-family: 'Campuni-Bold';
    color: #444444 !important
}

.Mui-focused option:focus {
    background: transparent !important;
}


#select-multiple-native option {
    padding: 10px;
    color: #C8CCD0 !important;
    background: transparent !important;
    font-family: 'Campuni-Medium';

}

.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
}

.no-fieldset fieldset {

    border: none !important;
}

.custom-select .MuiInput-root::before {
    border: none !important;
}

.dots {
    display: inline-block;
    width: 280px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.dots2 {
    display: inline-block;
    width: 240px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.react-multi-carousel-item {
    max-width: 316px !important;
    min-width: 316px !important;
}

.react-multi-carousel-item {
    padding: 10px;
}

.sectionAbout {
    background-image: url('./assets/bgsection.png');
    min-height: 700px;
    background-size: cover;
}

.paddingAbout {
    padding: 2rem 20rem;
}

.imgInitial {
    width: 80%;
}

.textCenter {
    width: 100%;
    display: flex;
    justify-content: start;
}

.MuiInputBase-input {
    height: 5px !important;
    padding: 20px 25px !important;
    font-family: 'Campuni-Medium' !important;
}

.MuiInputBase-inputMultiline {
    height: 69px !important;
    padding: 10px 0px !important;
}

#customInput {
    height: 30px !important;

}

.inputSelect .MuiInputBase-root {
    height: 44px !important;
    padding: 20px 0px !important;
}

.MuiFormLabel-root {
    font-family: 'AlegraSans' !important;
    font-weight: bold !important;
    color: #222222 !important;
}

.MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1) !important;
    font-weight: bold !important;
    color: #222222 !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    font-weight: bold !important;
    color: #222222 !important;
}

.MuiTextField-root {
    width: 100%;
}

.MuiTypography-root {
    font-family: 'Campuni-Medium' !important;
}

.MuiFormControlLabel-label {
    font-family: 'AlegraSans' !important;
    font-weight: bold !important;
    color: #222222 !important;
}

.custom-form-label span {
    font-size: 12px !important;
}

.MuiTabPanel-root {
    padding: 24px 0 !important;
}

.background-countdown {
    background: rgb(59, 231, 255);
    background: linear-gradient(90deg, rgba(59, 231, 255, 1) 0%, rgba(61, 104, 255, 1) 50%, rgba(63, 82, 255, 1) 100%);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

li[id*="blue"] .MuiTreeItem-label {
    color: #005EB8 !important;
    font-weight: 700;
    font-size:  14px;
}


li[id*="negrito"] .MuiTreeItem-label {
    color: #000 !important;
    font-weight: 700;
    font-size:  14px;
}

li[id*="black"] .MuiTreeItem-label {
    color: #666666 !important;
    font-weight: 500;
    font-size:  12px;
}

.MuiListItemText-primary {
    font-size: 0.9rem !important;
}

.menu-custom .Mui-selected {
    border-left: 5px #005EB8 solid !important;
    border-radius: 10px !important;
}

.customPagination button {
    font-family: 'Univers-Medium' !important;

}

.customPagination .Mui-selected {
    background: #005EB8 !important;
    color: white;
}

.customPagination .Mui-selected {
    color: white !important;
}

.scrollbar {
    margin-left: 30px;
    float: left;
    height: 300px;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 25px;
}

.force-overflow {
    min-height: 450px;
}

#wrapper {
    text-align: center;
    width: 500px;
    margin: auto;
}

.customSearchBar .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.MuiOutlinedInput-notchedOutline {
    border-radius: 9px !important;
}

.customSearchBar .MuiInputBase-formControl {
    background: #F3F4F7 !important;
}

.MuiButtonBase-root {
    box-shadow: none !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    font-family: 'Campuni-Medium' !important;
}

.MuiPaginationItem-root {
    border-color: #005EB8 !important;
    color: #005EB8 !important;
}

/* .Mui-active .MuiStepConnector-line {
    border-color: linear-gradient(95deg, #005EB8 0%, #40BBFF 100%) !important;
    height: 6px;

}

.Mui-complete .MuiStepConnector-line {
    border-color: linear-gradient(95deg, #005EB8 0%, #40BBFF 100%) !important;
    height: 6px;

} */


/* .MuiStepConnector-line {
    border-top-style: solid;
    border-top-width: 6px !important;
} */



/*
   *  STYLE 2
   */

#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #0e72b8;
}

/*
   *  STYLE 3
   */

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb {
    background-color: #0e72b8;
}

.fc .fc-timegrid-slot-label {
    height: 50px !important;
}


@media only screen and (max-width : 768px) {
    .paddingAbout {
        padding: 2rem 2rem;
    }

    .imgInitial {
        display: none;
    }

    .textCenter {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .footerResp {
        text-align: center;
        margin-top: 10px;
    }
}